import { Link } from "gatsby";
import * as React from "react";
import { Col, Row } from "reactstrap";
import ContentSection from "../../components/contentSection";
import ContractsList from "../../components/contract-vehicles/contractsList";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import NaicsCodes from "../../components/services/naicsCodes";
import capitol from "../../images/capitol.jpg";
import cmmiLogo from "../../images/cmmi-logo.png";
import isoLogo from "../../images/iso-logo.png";

class Federal extends React.Component {
    render() {
        return (
            <Layout>
                <SEO title="Federal Services" />

                <PageTitle
                    imageUrl={capitol}
                    title="Federal Government Services"
                />

                <ContentSection>
                    <Row>
                        <Col lg={6}>
                            <h2>Our Federal Clients</h2>

                            <ul>
                                <li>United States Army National Guard</li>
                                <li>United States Navy</li>
                                <li>United States Army Reserve</li>
                                <li>United States Marines</li>
                                <li>United States Army</li>
                                <li>United States Air Force Reserve</li>
                            </ul>
                        </Col>

                        <Col lg={6}>
                            <h2>Our Work Includes</h2>

                            <ul>
                                <li>Business Intelligence</li>
                                <li>Analytical Support</li>
                                <li>Mathematical Modeling</li>
                                <li>Application Development</li>
                                <li>Decision Support Systems</li>
                                <li>IT Integration</li>
                                <li>Data Warehousing</li>
                                <li>Human Resources Information Technology</li>
                                <li>Human Resources Process Improvement</li>
                                <li>
                                    Human Resources Personnel Action Requests
                                </li>
                                <li>
                                    Human Resources Compensation &amp; Benefits
                                </li>
                                <li>
                                    Human Resources Separation &amp; Retirement
                                </li>
                                <li>
                                    Human Resources Budget Planning &amp;
                                    Execution
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </ContentSection>

                <ContentSection title="Contract Vehicles">
                    <ContractsList />
                </ContentSection>

                <ContentSection title="NAICS Codes">
                    <NaicsCodes />
                </ContentSection>

                <ContentSection narrow>
                    <div className="mb-4">
                        <p>
                            DUNS Number: 138982710. CAGE Code: 3NWH6. Type of
                            Business: Woman-Owned Small Business.
                        </p>

                        <p>
                            Formal Accreditations: CMMI Level 3 | ISO 9001:2015
                        </p>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={cmmiLogo}
                            alt="CMMI for Development Level 3"
                            className="img-fluid mx-3"
                            style={{ height: 64 }}
                        />

                        <img
                            src={isoLogo}
                            alt="ISO 9001:2015 Certified Company"
                            className="img-fluid mx-3"
                            style={{ height: 64 }}
                        />
                    </div>
                </ContentSection>
            </Layout>
        );
    }
}

export default Federal;
