import * as React from "react";
import { Table } from "reactstrap";
import { NaicsCode } from "../../models";

const codes: NaicsCode[] = [
    {
        code: 518210,
        description: "DATA PROCESSING, HOSTING, AND RELATED SERVICES"
    },
    { code: 541219, description: "OTHER ACCOUNTING SERVICES" },
    { code: 541330, description: "ENGINEERING SERVICES" },
    {
        code: 541511,
        description: "(PRIMARY)	CUSTOM COMPUTER PROGRAMMING SERVICES"
    },
    { code: 541512, description: "COMPUTER SYSTEMS DESIGN SERVICES" },
    { code: 541513, description: "COMPUTER FACILITIES MANAGEMENT SERVICES" },
    { code: 541519, description: "OTHER COMPUTER RELATED SERVICES" },
    {
        code: 541611,
        description:
            "ADMINISTRATIVE MANAGEMENT AND GENERAL MANAGEMENT CONSULTING SERVICES"
    },
    { code: 541612, description: "HUMAN RESOURCES CONSULTING SERVICES" },
    {
        code: 541614,
        description:
            "PROCESS, PHYSICAL DISTRIBUTION, AND LOGISTICS CONSULTING SERVICES"
    },
    { code: 541618, description: "OTHER MANAGEMENT CONSULTING SERVICES" },
    {
        code: 541712,
        description:
            "RESEARCH AND DEVELOPMENT IN THE PHYSICAL, ENGINEERING, AND LIFE SCIENCES (EXCEPT BIOTECHNOLOGY)"
    },
    {
        code: 541990,
        description:
            "ALL OTHER PROFESSIONAL, SCIENTIFIC, AND TECHNICAL SERVICES"
    },
    { code: 561210, description: "FACILITIES SUPPORT SERVICES" },
    { code: 561440, description: "COLLECTION AGENCIES" },
    { code: 561611, description: "INVESTIGATION SERVICES" },
    {
        code: 611430,
        description: "PROFESSIONAL AND MANAGEMENT DEVELOPMENT TRAINING"
    },
    {
        code: 611699,
        description: "ALL OTHER MISCELLANEOUS SCHOOLS AND INSTRUCTION"
    },
    { code: 611710, description: "EDUCATIONAL SUPPORT SERVICES" },
    { code: 624190, description: "OTHER INDIVIDUAL AND FAMILY SERVICES" }
];

const NaicsCodes: React.FunctionComponent = () => (
    <Table striped bordered className="mb-0">
        <thead>
            <tr>
                <th>Code</th>
                <th>Description</th>
            </tr>
        </thead>

        <tbody>
            {codes.map(code => (
                <tr key={code.code}>
                    <td>{code.code}</td>
                    <td>{code.description}</td>
                </tr>
            ))}
        </tbody>
    </Table>
);

export default NaicsCodes;
